.hero-style-1 {
	.hero-main-col {
		.hero-headline {
			font-size:24px;
			text-shadow: 1px 1px 0 #303030;
			font-weight:700;
			font-family:$heading-font;
			@include media-breakpoint-up(lg) {
				font-size:55px;
			}
		}

		.hero-subline {
			font-size:18px;
			text-shadow: 1px 1px 0 #303030;
			font-family:$heading-font;
			@include media-breakpoint-up(lg) {
				font-size:36px;
			}
		}

		.hero-button {
			margin-top:45px;
		}
	}
}


.home-hero {
	.background-overlay {
		background:linear-gradient(135deg, rgba(25,25,25,0.8) 0%,rgba(25,25,25,0.6) 100%);
		position: absolute;
		left: auto;
		right: 0;
		top: 0;
		width: 72.5%;
		height: 100%;
		z-index: 100;
	}
	.hero-bkg-video {
		position: absolute;
		left: auto;
		right: 0;
		top: 0;
		width: 72.5%;
		overflow: hidden;
	}
	.glow {
		background-color: darken($brand-primary,10%);
		height: 75%;
		width: 100%;
		border-radius: 50%;
		position: absolute;
		left: -15%;
		top: -40%;
		filter: blur(14rem);
		opacity: 0.65;
	}
	&:after {
		content:'';
		position: absolute;
		bottom: -123px;
		right: -100px;
		background: #000;
		filter: blur(20rem);
		width: 68%;
		height: 20rem;
		z-index: -1;
		transform: rotate(4deg);
		opacity: 0.9;
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	.hero-content {
		z-index: 100;
		.hero-title {
			text-shadow: 0 0 16px rgba(0,0,0,0.45);
			font-size: 4.5rem;
			letter-spacing: 3px;
			@include media-breakpoint-up(lg) {
				font-size: 5.5rem;
			}
		}
	}
	.lower-hero-content {
		position: absolute;
		right: 11%;
		width: 200px;
		height: 225px;
		bottom: -75px;
		z-index: 200;
		i, svg {
			font-size: 26px;
		}
		.small-break {
			height: 2px;
			width: 70px;
			background: #fff;
			margin: 10px 0 10px;
			opacity: 0.45;
		}
		p {
			margin-bottom: 0;
		}
	}
}

body {
	position: relative;
	.lines {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		height: 100%;
		margin: auto;
		width: 90%;
		opacity: 0.3;
		pointer-events: none;

	.line {
		position: absolute;
		width: 1px;
		height: 100%;
		top: 0;
		background: rgba(255, 255, 255, 0.1);
		overflow: hidden;
		animation-delay: 4s;
		pointer-events: none;

		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 15vh;
			width: 100%;
			top: -50%;
			left: 0;
			pointer-events: none;
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
			animation: drop 18s 0s infinite;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
		}

		&:nth-child(1) {
			left: 0%;
			&::after {
				animation-delay: 4s;
		}
	 }
	 &:nth-child(2) {
		 left: 25%;
		 &::after {
			 animation-delay: 8s;
			 }
		}
		&:nth-child(3) {
			left: 50%;
			&::after {
				animation-delay: 12s;
				}
		 }
		 &:nth-child(4) {
			 left: 75%;
			 &::after {
				 animation-delay: 16s;
				 }
			}
			&:nth-child(5) {
				left: 100%;
				&::after {
					animation-delay: 20s;
					}
			 }
		}
	}
}

@keyframes drop {
  0% {
    top: -10%;
	}
  100% {
    top: 110%;
	}
}

.rpb-simple-content-half-width {
	.brightBlur {
		background: $brand-primary;
		height: 75%;
		width: 56%;
		border-radius: 50%;
		position: absolute;
		right: -30%;
		bottom: -40%;
		filter: blur(20rem);
		opacity: 0.4;
		z-index: -1;
		transform: rotate(4deg);
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
}

.home-services {
	position: relative;
	z-index: 200;

	.the-service {
		border: 1px solid rgba(255,255,255,0.15);
		background-color: $dark;
		i,svg {
			font-size: 36px;
			color: $light;
		}
		.small-break {
			height: 2px;
			width: 70px;
			background: #fff;
			margin: 20px 0 18px;
			opacity: 0.35;
		}
		.the-service-content {
			opacity: 0.75;
				p {
					margin-bottom: 0;
				}
		}
	}
}
