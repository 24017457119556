.rpb-accordion {
  .accordion-item {
    .accordion-body {
      p {
        &:last-of-type {
          margin-bottom:0;
        }
      }
    }
  }
}