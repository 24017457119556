.location {
  position:relative;
}

.single-location {
  .breadcrumb {
    font-size:12px;
  }
  .location-cannabis-license {
    font-size:12px;
    color:#555;
  }
  .single-location-info-content {
    word-wrap:break-word;
    p {
      margin-bottom:2rem;
      i,svg {
        color:$brand-primary;
      }
      .content-icon {
        padding-bottom:1rem;
        padding-right:10px;
      }
      &.info-address {
        a {
          font-weight:400;
          color:inherit;
          &:hover {
            text-decoration:underline;
          }
        }
      }
      &.info-directions {
        &:hover {
          text-decoration: underline;
          color:$brand-primary;
        }
      }
      &.info-phone {
        a[href^=tel] {
          font-weight:400;
          color:$brand-primary;
          &:hover {
            text-decoration:underline;
          }
        }
      }
      &.info-email {
        a[href^=mailto] {
          font-weight:400;
          color:$brand-primary;
          &:hover {
            text-decoration:underline;
          }
        }
      }
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }

  .location-hr {
    width:100%;
    height:1px;
    background:lighten($dark,75%);
    content:'';
    margin:2rem 0;
    box-sizing:content-box;
  }

  // core information
  .core-information-col {
  }
  // hours of operation
  .location-hours {
    .location-hours-table {
      @include media-breakpoint-up(lg) {
        max-width:75%;
      }
      @include media-breakpoint-up(xxl) {
        max-width:50%;
      }
      .location-hours-row {
        align-items:center;
        justify-content: space-between;
        margin-bottom:1rem;
      }
    }
  }
  // general content
  .single-location-general-content {
    p {
      margin-bottom:1rem;
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
  // social media
  .location-social-media {
    .location-social-single {
      padding-right:25px;
      width:auto;
      a {
        color:$brand-primary;
        font-weight:400;
        i,svg {
          font-size:150%;
        }
        &:hover {
          color:$dark;
        }
      }
      .social-text {
        display:none;
      }
    }
  }

  // reviews
  .location-reviews-container {
    background:white;
    .location-reviews {
      .location-reviews-slider {
        .splide__slider {
          .rpb-slider-arrows {
            .splide__arrow {
              background:$brand-primary;
              svg {
                color:white;
              }
            }
          }
        }
        .location-single-review {
          display:flex;
          flex-direction:column;
          justify-content:center;
          height:100%;
          .location-single-review-icon {
            font-size:50px;
            margin-bottom:1rem;
            opacity:0.15;
          }
          .location-single-review-content {
            font-style:italic;
            margin-bottom:1rem;
            padding-left:60px;
            padding-right:60px;
          }
          .location-single-review-name {
            span {
              padding:10px 30px;
              border-radius:999px;
              text-transform:uppercase;
              color:white;
            }
          }
        }
      }
    }
  }

  // photo gallery
  .location-photos-wrapper {
    height:350px;
    @include media-breakpoint-up(lg) {
      height:50%;
      min-height:350px;
    }
  }

  // map
  .single-location-map {
    height:350px;
    @include media-breakpoint-up(lg) {
      height:50%;
      min-height:350px;
    }
    iframe {
      width:100%;
      height:350px;
      @include media-breakpoint-up(lg) {
        height:100%;
        min-height:350px;
      }
    }
  }

  // cannabis menu embed
  .location-menu {
    position:relative;
    z-index:9999;
  }
}

.single-location-alert {
  background:#C80030;
  color:white;
  margin-bottom:0;
  border:0;
  color:white;
  border-radius:0;
  p {
    &:last-of-type {
      margin-bottom:0;
    }
  }
  .btn-close {
    color:white;
    background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    padding:0;
    height:100%;
  }
}
