.rpb-cards {
  .card {
    border:0;
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }
  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
        }
        .splide__arrow--next {
          right:0;
        }
      }
      .splide__track {
        margin-left:3rem;
        margin-right:3rem;
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
  }
}
