// Half Width

.half-content {
  position: relative;
  background-color: $white;

  .canvas {
    opacity: 0;
    @include media-breakpoint-up(lg) {
      opacity: 0.75;
    }
  }

  .half-image {
    position: relative;
    pointer-events: none;
    display: block;
    margin: 1rem auto;
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(md) {
      position: absolute;
      width: 50%;
      height: 100%;
      right: 0;
      top: 0;
      margin: 0;
      &.img-left {
        right: auto;
        left: 0;
      }
    }
  }
}

// Full Width

.whole-content {
  .whole-image {
    position: relative;
    display: block;
    margin: 1rem auto;
    height: 350px;
    width: 100%;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(md) {
      position: absolute;
      height: 100%;
      width: 100%;
      margin: 0;
      right: 0;
      top: 0;
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      // background-attachment: fixed;
    }
  }

  .whole-content-row {
    position: relative;
    overflow: hidden;
    z-index: 2;

    .hc-content-col {
      background: $white;
      @include media-breakpoint-up(md) {
        background: rgba($white,0.95);
        }
      }
  }

  .hc-content-row {
    .hc-content-col {
      @include media-breakpoint-up(md) {
        background: rgba($white,0.95);
        }
      }
    }

}