.rpb-blog-feed {
  // Style 1
  &.feed-style-1 {
    .blog-page-postcontent {
      display:flex;
      flex-direction:column;
      width:100%;
      .circle-link {
        position:absolute;
        top:0;
        right:0;
      }
    }
    .blog-feed-slider {
      .splide__slider {
        .rpb-slider-arrows {
          .splide__arrow--prev {
            left: 0;
          }
          .splide__arrow--next {
            right: 0;
          }
        }
        .splide__track {
          margin-left: 3rem;
          margin-right: 3rem;
          padding-top: 30px;
        }
        .splide__pagination {
          bottom: -2.5rem;
        }
      }
    }
    // correction for setting image dimensions (google page speed)
    img {
      width:auto;
      height:auto;
    }
  }
  &.feed-style-2 {
    .blog-feed-slider {
      .splide__slider {
        .rpb-slider-arrows {
          display:flex;
          align-items:center;
          justify-content:center;
          margin-top:1rem;
          @include media-breakpoint-up(lg) {
            position:absolute;
            display:block;
            top:-100px;
            right:0;
            margin-top:0;
          }
          .splide__arrow {
            display:inline-block;
            position:relative;
            transform:none;
            height:50px;
            width:50px;
            border: 1 px solid rgba(0,0,0,.25);
            transition:all 350ms ease-in-out;
            &:hover {
              background:rgba(0,0,0,.05);
            }
            i,svg {
              color:#191919;
              display:flex;
              margin:0 auto;
              opacity:0.5;
            }
          }
          .splide__arrow--prev {
            left:auto;
            margin-right:5px;
            @include media-breakpoint-up(lg) {
              margin-right:0;
            }
          }
          .splide__arrow--next {
            right:auto;
            margin-left:5px;
            @include media-breakpoint-up(lg) {
              margin-left:0;
            }
          }
        }
        .splide__track {
        }
        .splide__pagination {
        }
      }
    }
    .blog-post-content-row {
      @include media-breakpoint-up(lg) {
        min-height: 325px;
      }
      .post-featured-image {
        height: 100%;
        width: 100%;
        min-height:150px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
        position: relative;
        .blog-date {
          color: #191919;
          font-size: 13px;
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 10px 10px;
          background: #fff;
          max-width: 120px;
          border-radius: 0 4px 0 0;
          i {
            text-shadow: 0 0 20px rgba(221, 160, 30, 1);
          }
        }
      }
      .post-content {
        position: relative;
        display:flex;
        flex-direction:column;
        .post-content-inner  {
          // background:white;
          padding:20px;
          @include media-breakpoint-up(lg) {
            background:transparent;
            padding: 0 1.5rem 0 .5rem;
            display:flex;
            flex-direction:column;
            height:100%;
          }
        }
        .blog-title {
          font-weight:bold;
        }

        .blog-excerpt {
          margin-top: 1rem;
          margin-bottom: 1rem;
          line-height: 1.5;
          opacity: 0.8;
          @include media-breakpoint-up(lg) {
            margin-top:0;
          }
          a {
            color:inherit;
            font-weight:400;
            &:hover {
              color:inherit;
            }
          }
        }
        .blog-cats {
          margin-top:auto;
          .blog-cat {
            color: inherit;
            font-weight: bold;
            font-size: 11px;
            display:inline-block;
            margin-bottom:5px;
            border: 1px solid;
            padding: 2px 10px;
            border-radius: 50px;
            transition: all 0.25s;
            background: transparent;
          }
        }
      }
    }
  }
}
