.is-mega {
  .menu-main-menu-container, .menu-primary-navigation-container, .menu-centered-nav-left-container, .menu-centered-nav-right-container {
    position: relative;
    .has-mega-menu {
      position: unset;
      .dropdown-menu {
        min-height: 200px;
        .mega-title {
          flex-basis:100%!important;
          border-bottom:1px solid rgba($black,0.1)!important;;
          padding-bottom:10px!important;
          margin-bottom:10px!important;;
          a {
            font-size:24px;
            text-transform:none;
            color:$brand-primary;
            &:hover {
              background:transparent!important;
            }
          }
          @include media-breakpoint-down(xl) {
            display:none!important;
          }
        }
      }
    }
  }
  // specific styles
  &.mega-full-background {
    .dropdown-menu {
      background: transparent;
      display:block;
    }
    .mega-menu-image {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-size:cover;
      background-repeat:no-repeat;
      background-position: center;
    }
  }
  &.mega-partial-background {
    .menu-main-menu-container, .menu-primary-navigation-container, .menu-centered-nav-left-container, .menu-centered-nav-right-container {
      .has-mega-menu {
        .dropdown-menu {
          background: linear-gradient(270deg, $dark 0%, lighten($dark,15%) 90%);
          padding-right:128%;
          flex-flow:wrap;
          align-items:center;
          padding-top:15px;
          padding-bottom:15px;
          display:flex;
          justify-content: center;
          &.show {
            display:flex;
          }
          li {
            flex-basis:33.33%;
            border-bottom:0;
            padding:20px;
            text-align: center !important;
            .dropdown-item {
              word-break:break-word;
            }
          }
          @include media-breakpoint-up(md) {
            left:auto!important;
            right:0!important;
            width:600px !important;
          }
          @include media-breakpoint-up(xl) {
            left:auto!important;
            right:0!important;
            width:1100px !important;
          }
          @include media-breakpoint-up(xxl) {
            width: 1300px !important;
          }
        }
        .mega-menu-image {
          position: absolute;
          width: 40%;
          height: 100%;
          right:0;
          z-index: -1;
          background-size:cover;
          background-position: center;
          background-repeat:no-repeat;
        }
      }
    }
  }
}
