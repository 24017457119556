/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_legacy_markup_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper textarea {
  background-color: transparent;
  border: 2px solid rgba($white,.5);
  color: $white;
  padding: 10px 15px !important;

  &:focus,&:active {
    border-color: $brand-primary;
  }
}
